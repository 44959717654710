import gql from 'graphql-tag'

export const CONTINUE_ON_STEP_REFERENCE = gql`
  fragment continueOnStepReferenceFields on CourseContinueStepReference {
    id
    title
    reference
    courseSection {
      id
      title
      courseSegment {
        id
        title
        reference
      }
    }
  }
`

export const COURSE_STEP_REFERENCE = gql`
  fragment courseStepReferenceFields on CourseStepReference {
    id
    title
    reference
    courseSection {
      id
      title
      courseSegment {
        id
        title
        reference
      }
    }
  }
`

export const COURSE = gql`
  fragment courseFields on Course {
    categories {
      id
      title
    }
    learningOutcomes {
      id
      title
      imageUrl
    }
    courseProvider {
      ...courseProviderFields
    }
    credits
    ctaUrl
    deliveryMethod
    durationDescription
    hoursPerWeek
    id
    imageUrl
    interviewUrl
    isComingSoon
    isFree
    isRecommended
    nzqaLevel
    overview
    pageDetailsJson
    paymentMethods
    period
    priceAU
    priceNZ
    priceUS
    programme {
      id
      title
      slug
      courseProvider {
        ...courseProviderFields
      }
    }
    slug
    soiUrl
    startType
    # timeframe
    timeframeWeeks # don't use timeframe
    title
    type
    typeSlug
  }
`

// Enough information for a complete course card
export const PUBLIC_COURSE = gql`
  fragment publicCourseFields on Course {
    ...courseFields
    publicIntakes {
      id
      title
      slug
      enrolUrl
      timeframe
      dateStart
      dateEnd
      isEnrolled
      canEnrol
      courseEdition {
        id
        title
        version
        overview
        imageUrl
        slug
        isPublished
        canView
        dateStart
        dateEnd
        durationInDays
        courseSegments {
          id
          reference
          title
          courseSections {
            id
            title
          }
        }
      }
    }
  }
  ${COURSE}
`

// Pull down just enough information to display intake selector
// when enrolling for a given course
export const COURSE_WITH_INTAKES = gql`
  fragment courseWithIntakesFields on Course {
    ...courseFields
    publicIntakes {
      id
      timeframe
      canEnrol
      courseEdition {
        id
        dateStart
        dateEnd
      }
    }
  }
`

export const PUBLIC_INTAKE_FIELDS = gql`
  fragment publicIntakeFields on CourseIntake {
    id
    title
    slug
    dateStart
    dateEnd
    isEnrolled
    canEnrol
    courseEdition {
      id
      title
      slug
      dateStart
      dateEnd
      course {
        ...courseWithIntakesFields
      }
    }
  }
`
